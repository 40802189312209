body {
  margin: 0;
  font-family: 'Lucida Sans',
    'Lucida Sans Regular',
    'Lucida Grande',
    'Lucida Sans Unicode',
    Geneva,
    Verdana,
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}