body,
html {
  height: 100%;
  margin: 0;
}

.app-container {
  padding: 20px;
}

.container {
  display: flex;
  height: 85%;
}

.left-column {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: #f9f9f9;
}

.plot {
  width: 80%;
  height: 300px;
  /* Set the height as per your requirement */
  border: 1px solid #ddd;
  margin-bottom: 20px;
}

.inputs {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.input-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.input-container {
  display: flex;
  flex-direction: column;
  margin-right: 20px;
}

input {
  padding: 10px;
  margin-top: 5px;
}

.right-column {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #e9e9e9;
}

p {
  padding: 10px;
}

h1 {
  text-align: center;
}